import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import LogoWhite from "../images/logo-white.inline.svg"
import ShopifyPartner from "../images/shopify-partner.inline.svg"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      EbayPartner: file(relativePath: { eq: "ebay-partner.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allServicesYaml(sort: { fields: title }) {
        edges {
          node {
            title
            description
            offerings {
              description
              title
            }
          }
        }
      }
    }
  `)

  return (
    <div className="footer">
      <div className="container">
        <div className="footer__company">
          <div className="footer__logo">
            <Link to="/">
              <LogoWhite />
            </Link>
          </div>
          <div className="footer__address">
            <a href="mailto:team@uprightlabs.com" className="tel">
              team@uprightlabs.com
            </a>
            <br />
            <br />

            <a className="address" target="_blank" rel="noreferrer">
              Upright Labs <br />
              a Business Unit of Cordance Operations LLC<br />
              16 W Martin Street, Raleigh, NC 27601
            </a>
            <Img
              style={{ margin: `3rem 0 1rem 0` }}
              className={"partner-logo"}
              fluid={data.EbayPartner.childImageSharp.fluid}
              alt="Ebay Bronze Partner"
            />

            <ShopifyPartner className="partner-logo"></ShopifyPartner>
          </div>
        </div>

        <div className="footer__meta">
          <form
            className="footer__form"
            name="newsletter"
            method="POST"
            action="/newsletter-confirmation/"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="newsletter" />
            <input name="bot-field" type="hidden" />
            <label htmlFor="email-signup" className="footer__form-label">
              Sign up for our industry insights newsletter
            </label>
            <input
              type="email"
              id="email-signup"
              placeholder="Email"
              name="newsletter_email"
              className="footer__form-input"
              required
            />
            <button
              type="submit"
              className="button button--yellow footer__form-submit"
            >
              Sign up
            </button>
          </form>

          <nav className="footer__nav">
            <div className="footer__nav-col">
              <h2>Company</h2>

              <ul className="footer__nav-list">
                <li className="footer__nav-listitem">
                  <Link to="/company/" className="footer__nav-anchor">
                    Company
                  </Link>
                </li>
                <li className="footer__nav-listitem">
                  <a
                    href="https://weworkremotely.com/company/upright-labs"
                    className="footer__nav-anchor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Careers
                  </a>
                </li>
                <li className="footer__nav-listitem">
                  <Link to="/privacy/" className="footer__nav-anchor">
                    Privacy Policy
                  </Link>
                </li>
                <li className="footer__nav-listitem">
                  <Link
                    to="https://cordance.co/uprightlabs-tos-us/"
                    className="footer__nav-anchor"
                  >
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer__nav-col">
              <h2>Services</h2>

              <ul className="footer__nav-list">
                {data.allServicesYaml.edges.map(({ node }, index) => {
                  return (
                    <li key={index} className="footer__nav-listitem">
                      <Link
                        to={`/services#${node.title}/`}
                        className="footer__nav-anchor"
                      >
                        {node.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="footer__nav-col">
              <h2>Software</h2>

              <ul className="footer__nav-list">
                <li className="footer__nav-listitem">
                  <Link to="/software/" className="footer__nav-anchor">
                    Features
                  </Link>
                </li>

                <li className="footer__nav-listitem">
                  <a
                    href="https://app.uprightlabs.com"
                    target="_blank"
                    rel="noreferrer"
                    className="footer__nav-anchor"
                  >
                    Lister
                  </a>
                </li>
                <li className="footer__nav-listitem">
                  <a
                    href="https://intercom.help/upright-labs/en/"
                    className="footer__nav-anchor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Documentation
                  </a>
                </li>
              
                <li className="footer__nav-listitem">
                  <Link to="/refer/lister/" className="footer__nav-anchor">
                    Refer
                  </Link>
                </li>

                {/* <li className="footer__nav-listitem">
                  <a href="" className="footer__nav-anchor">
                    Local
                  </a>
                </li>
                <li className="footer__nav-listitem">
                  <a href="" className="footer__nav-anchor">
                    Integrations
                  </a>
                </li>
                <li className="footer__nav-listitem">
                  <a href="" className="footer__nav-anchor">
                    Getting started
                  </a>
                </li>
                 */}
              </ul>
            </div>

            <div className="footer__nav-col">
              <h2>Resources</h2>

              <ul className="footer__nav-list">
                <li className="footer__nav-listitem">
                  <Link to="/case-studies/" className="footer__nav-anchor">
                    Case studies
                  </Link>
                </li>
                <li className="footer__nav-listitem">
                  <Link to="/blog/" className="footer__nav-anchor">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Footer
