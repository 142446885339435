import React from "react"
import Footer from "./footer"
import Nav from "./nav"

import "../sass/style.scss"

const Layout = ({ children }) => {
  React.useEffect(() => {
    const nav = document.querySelector(".nav")
    const toggleNav = document.querySelector(".nav-toggle")

    toggleNav.addEventListener("click", () => {
      nav.classList.toggle("open")
    })
  }, [])

  return (
    <>
      <Nav />
      {children}
      <Footer></Footer>
    </>
  )
}

export default Layout
