import { Link } from "gatsby"
import React from "react"
import LogoBlack from "../images/logo-black.inline.svg"

const Nav = () => {
  return (
    <div className="nav">
      <div className="container">
        <Link to="/" className="nav-logo">
          <LogoBlack />
        </Link>

        <nav className="nav-link-container">
          <Link className="nav-link" to="/services">
            Services
          </Link>
          <Link className="nav-link" to="/software">
            Software
          </Link>
          <Link className="nav-link" to="/resources">
            Resources
          </Link>
          <Link className="nav-link" to="/company">
            Company
          </Link>
          <Link className="nav-link" to="/contact">
            Contact
          </Link>
        </nav>
        <div className="nav-toggle"></div>
      </div>
    </div>
  )
}

export default Nav
